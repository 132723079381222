import React, { ChangeEvent, FC, useContext, useState } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { prettifyOrgNumber } from "utils/helpers";
import { ModalContext } from "contexts/modals";
import { IExternalMembership } from "reducers/externalMemberships";
import ExternalMembershipInformation from "components/drawers/externalMembershipInformation";
import { THeadCell } from "utils/types";
import { LocalizeText } from "components/localizer";
import { TAgreementType } from "reducers/memberships";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { Box, FormControlLabel, Switch } from "@mui/material";
import StyledSwitchContainer from "components/tableComponents/switchContainer";

const headCells = (hideCommunityColumn?: boolean): THeadCell[] => {
    const cells = [
        {
            id: "name",
            sortId: "organization__name",
            label: <LocalizeText tag="commercialCompanyNameShort" />,
        },
        {
            id: "org_nr",
            sortId: "organization__org_nr",
            label: <LocalizeText tag="orgNumberShort" />,
        },
        { id: "community_title", sortId: "default_community__title", label: <LocalizeText tag="arena" /> },
        {
            id: "agreement_nr",
            sortId: "agreement_nr",
            label: <LocalizeText tag="customerNumberShort" />,
        },
        { id: "invoice_ref", sortId: "invoice_ref", label: <LocalizeText tag="invoiceReference" /> },
        { id: "contact_person", sortId: "contact_person", label: <LocalizeText tag="referencePerson" /> },
    ];
    if (hideCommunityColumn) {
        cells.splice(2, 1);
    }
    return cells;
};

interface IExternalMembershipTableProps {
    pagination: IUsePaginationStore<"external-memberships">;
    agreement_type: TAgreementType;
    hideCommunityColumn?: boolean;
}

const ExternalMembershipTable: FC<IExternalMembershipTableProps> = ({
    pagination,
    agreement_type,
    hideCommunityColumn,
}) => {
    const { openDrawer } = useContext(ModalContext);
    const [showArchivedCustomers, setShowArchivedCustomers] = useState(false);

    const onIncludeArchivedChange = (event: ChangeEvent<HTMLInputElement>): void => {
        pagination.setParam("is_archived", event.target.checked ? null : false);
        setShowArchivedCustomers(event.target.checked);
    };

    const mapRows = (memberships: IExternalMembership[]): IRow[] =>
        memberships.map((membership) => {
            const { organization, agreement_nr, default_community, user_invoice_reference, contract_contact_name, is_archived } =
                membership;
            const { name, org_nr } = organization;
            const data = [
                name || "-",
                org_nr ? prettifyOrgNumber(org_nr) : "-",
                default_community?.title ?? "-",
                agreement_nr || "-",
                user_invoice_reference || "-",
                contract_contact_name || "-"
            ];

            if (hideCommunityColumn) {
                data.splice(2, 1);
            }

            return {
                onClick: () =>
                    openDrawer(
                        ExternalMembershipInformation,
                        { membership, pagination },
                        agreement_type === "external_member" ? "showExternalMembership" : "showSergelMembership"
                    ),
                data,
                disabled: is_archived
            };
        });

    const rows = mapRows(pagination.results);

    return (
        <Box>
            <StyledSwitchContainer>
                <FormControlLabel
                    control={
                        <Switch checked={showArchivedCustomers} onChange={onIncludeArchivedChange} color="primary" />
                    }
                    label={<LocalizeText tag="includeArchivedCustomers" />}
                />
            </StyledSwitchContainer>
            <TableComponent
                headCells={headCells(hideCommunityColumn)}
                isLoading={pagination.query.isFetching}
                rows={rows}
                skeletonVariant="options"
                pagination={pagination}
            />
        </Box>
    );
};

export default ExternalMembershipTable;
