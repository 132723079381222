import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { LocalizeText } from "components/localizer";
import { ModalContext } from "contexts/modals";
import { Box, Checkbox, FormControlLabel, List, ListItem, TextField, Tooltip } from "@mui/material";
import { MembersContext } from "contexts/members";
import { IUsePaginationStore } from "utils/usePaginationStore";
import { usePermission } from "utils/hooks/usePermission";
import { DatePicker } from "components/datePicker";
import { dateFormats } from "utils/formats";
import { differenceInCalendarDays, format, isAfter, isBefore, isToday, isValid, max, min, parseISO } from "date-fns";
import { ISubscription } from "reducers/subscriptions";
import { TLanguageTag } from "contexts/language";
import { IMemberSubscriptionAssociation } from "reducers/members";
import ConfirmDialog from "components/confirmDialog";
import VKButton from "components/vkButton";
import validator from "utils/validator";

interface IProps {
    association: IMemberSubscriptionAssociation;
    subscription: ISubscription;
    pagination: IUsePaginationStore<"member-subscription-associations">;
    updateSubscription: () => Promise<void>;
    onConfirmRemove: () => void;
}

export const EditMember: FC<IProps> = ({
    association,
    subscription,
    pagination,
    updateSubscription,
    onConfirmRemove,
}) => {
    const { fetchAssociation, updateAssociation, isUpdatingAssociation } = useContext(MembersContext);
    const { closeDrawers } = useContext(ModalContext);
    const { removeAssociation, isRemovingAssociation } = useContext(MembersContext);
    const { hasPermission } = usePermission();
    const [email, setEmail] = useState(association.member.email);
    const [sendEmail, setSendEmail] = useState<boolean>(association.should_send_mail);
    const [activateDate, setActivateDate] = useState<Date>(parseISO(association.activates ?? ""));
    const [deactivateDate, setDeactivateDate] = useState<Date | null>(
        association.deactivates ? parseISO(association.deactivates) : null
    );
    const [confirmDeactivate, setConfirmDeactivate] = useState(false);
    const [confirmRemoveUser, setConfirmRemoveUser] = useState(false);
    const [deactivateNow, setDeactivateNow] = useState<boolean>(false);
    const [canDeactivateNow, setCanDeactivateNow] = useState<boolean>(false);
    const [activateValidation, setActivateValidation] = useState({ isValid: true, reasonTag: "" });
    const [deactivateValidation, setDeactivateValidation] = useState({ isValid: true, reasonTag: "" });

    useEffect(() => {
        (async () => {
            const associationRefreshed = await fetchAssociation(association.uuid);
            setCanDeactivateNow(associationRefreshed.can_deactivate_now);
        })();
    }, [association.uuid]);

    const getMinActivationDate = useMemo((): Date => {
        if (
            association.deactivates &&
            parseISO(subscription.earliest_member_activation_date ?? "") > parseISO(association.deactivates ?? "")
        ) {
            return max([new Date(), subscription.start_date]);
        }
        return parseISO(subscription.earliest_member_activation_date ?? "");
    }, [association.deactivates, subscription.earliest_member_activation_date, subscription.start_date]);

    const getMaxActivationDate = useMemo((): Date | undefined => {
        const subscriptionEndDate = subscription.end_date ? parseISO(subscription.end_date) : undefined;
        const associationEndDate = association.deactivates ? parseISO(association.deactivates) : undefined;
        if (subscriptionEndDate && associationEndDate) {
            return min([subscriptionEndDate, associationEndDate]);
        }
        return subscriptionEndDate || associationEndDate;
    }, [association.deactivates, subscription.end_date]);

    const getMinDeactivationDate = useMemo((): Date => {
        const officialEarliestDeactivationDate = parseISO(subscription.earliest_cancellation_date ?? "");
        return hasPermission("can_override_earliest_subscription")
            ? max([new Date(), activateDate])
            : max([officialEarliestDeactivationDate, activateDate]);
    }, [activateDate, hasPermission, subscription.earliest_cancellation_date]);

    const getMaxDeactivationDate = useMemo((): Date | undefined => {
        const subscriptionEndDate = subscription.end_date ? parseISO(subscription.end_date ?? "") : undefined;
        return association.is_future_additional
            ? parseISO(subscription.latest_member_deactivation_date ?? "")
            : subscriptionEndDate;
    }, [association.is_future_additional, subscription.end_date, subscription.latest_member_deactivation_date]);

    const emailValid = useMemo(() => {
        return validator.email(email, true);
    }, [email]);

    const helperText = useMemo(() => {
        if (deactivateValidation.reasonTag) {
            return <LocalizeText tag={deactivateValidation.reasonTag as TLanguageTag} />;
        }
        if (!deactivateDate) {
            return "";
        }
        if (isToday(deactivateDate)) {
            return <LocalizeText tag="today" />;
        }
        return (
            <LocalizeText
                tag="inXDays"
                replace={{ days: differenceInCalendarDays(deactivateDate, new Date()).toString() }}
            />
        );
    }, [deactivateValidation.reasonTag, deactivateDate]);

    const onSave = useCallback(async (): Promise<void> => {
        const isActivatedChanged = activateDate.getTime() !== parseISO(association.activates ?? "").getTime();
        const isDeactivatesChanged =
            (deactivateDate && deactivateDate.getTime() !== parseISO(association.deactivates ?? "").getTime()) ||
            !!deactivateDate !== !!association.deactivates;

        const data = {
            ...{ subscription: subscription.uuid },
            ...(email !== association.member.email && { email: email }),
            ...(sendEmail !== association.should_send_mail && { should_send_mail: sendEmail }),
            ...(isActivatedChanged && { activates: format(activateDate, dateFormats.WEBDATE) }),
            ...(isDeactivatesChanged && {
                deactivates: deactivateDate ? format(deactivateDate, dateFormats.WEBDATE) : null,
            }),
        };

        await updateAssociation(association.uuid, data);
        await updateSubscription();
        closeDrawers();
        await pagination.query.refetch();
    }, [
        activateDate,
        association.activates,
        association.deactivates,
        association.member.email,
        association.should_send_mail,
        association.uuid,
        deactivateDate,
        subscription.uuid,
        email,
        sendEmail,
        updateAssociation,
        updateSubscription,
        closeDrawers,
        pagination.query,
    ]);

    const onConfirm = useCallback(async (): Promise<void> => {
        if (deactivateNow) {
            setConfirmDeactivate(true);
        } else {
            onSave();
        }
    }, [deactivateNow, onSave]);

    const onRemoveAssociation = useCallback(async (): Promise<void> => {
        await removeAssociation(association.uuid);
        closeDrawers();
        onConfirmRemove();
        await updateSubscription();
    }, [association.uuid, closeDrawers, onConfirmRemove, removeAssociation, updateSubscription]);

    const validateActivateDateCallback = useCallback(
        async (date: Date) => {
            setDeactivateValidation({ isValid: true, reasonTag: "" });
            if (!isValid(date)) {
                return setActivateValidation({ isValid: false, reasonTag: "" });
            }
            if (isBefore(date.setHours(0, 0, 0, 0), getMinActivationDate.setHours(0, 0, 0, 0))) {
                return setActivateValidation({ isValid: false, reasonTag: "errorMinDate" });
            }
            if (getMaxActivationDate && isAfter(date.setHours(0, 0, 0, 0), getMaxActivationDate.setHours(0, 0, 0, 0))) {
                return setActivateValidation({ isValid: false, reasonTag: "errorMaxDate" });
            }
            if (deactivateDate && isAfter(date, deactivateDate)) {
                return setActivateValidation({ isValid: false, reasonTag: "errorActivatsDeactivatesDate" });
            }
            return setActivateValidation({ isValid: true, reasonTag: "" });
        },
        [deactivateDate, getMaxActivationDate, getMinActivationDate]
    );

    const validateDeactivateDateCallback = useCallback(
        async (date: Date) => {
            setActivateValidation({ isValid: true, reasonTag: "" });

            if (!date && association.is_future_additional) {
                return setDeactivateValidation({ isValid: false, reasonTag: "errorFutureAdditional" });
            }
            if (!date) {
                return setDeactivateValidation({ isValid: true, reasonTag: "" });
            }
            if (!isValid(date)) {
                return setDeactivateValidation({ isValid: false, reasonTag: "" });
            }
            if (
                getMaxDeactivationDate &&
                isAfter(date.setHours(0, 0, 0, 0), getMaxDeactivationDate.setHours(0, 0, 0, 0))
            ) {
                return setDeactivateValidation({ isValid: false, reasonTag: "errorMaxDate" });
            }
            if (isBefore(date.setHours(0, 0, 0, 0), getMinDeactivationDate.setHours(0, 0, 0, 0))) {
                return setDeactivateValidation({ isValid: false, reasonTag: "errorMinDate" });
            }
            if (isBefore(date.setHours(0, 0, 0, 0), activateDate.setHours(0, 0, 0, 0))) {
                return setDeactivateValidation({ isValid: false, reasonTag: "errorActivatsDeactivatesDate" });
            }
            return setDeactivateValidation({ isValid: true, reasonTag: "" });
        },
        [activateDate, association.is_future_additional, getMinDeactivationDate, getMaxDeactivationDate]
    );

    const onChangeActivationDate = useCallback(
        (date: unknown): void => {
            setActivateDate(date as Date);
            validateActivateDateCallback(date as Date);
        },
        [validateActivateDateCallback]
    );

    const onChangeDeactivationDate = useCallback(
        (date: unknown): void => {
            setDeactivateDate(date as Date);
            validateDeactivateDateCallback(date as Date);
        },
        [validateDeactivateDateCallback]
    );

    return (
        <Box>
            <List>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="email" />}
                        fullWidth
                        value={email}
                        disabled={isBefore(parseISO(association.activates ?? ""), new Date())}
                        error={!emailValid}
                        helperText={!emailValid ? <LocalizeText tag="errorEmail" /> : null}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </ListItem>
                {hasPermission("is_community_user") ? (
                    <ListItem>
                        <FormControlLabel
                            label={<LocalizeText tag="sendEmail" />}
                            disabled={isBefore(parseISO(association.activates ?? ""), new Date())}
                            control={<Checkbox checked={sendEmail} onChange={(e) => setSendEmail(e.target.checked)} />}
                        />
                    </ListItem>
                ) : null}
                <ListItem>
                    <LocalizeText tag="addMemberInfo" />
                </ListItem>
                <ListItem>
                    <LocalizeText tag="activatesDateInfo" />
                </ListItem>
                <ListItem>
                    <DatePicker
                        minDate={getMinActivationDate}
                        maxDate={getMaxActivationDate}
                        format={dateFormats.WEBDATE}
                        label={<LocalizeText tag="activatesDate" />}
                        value={activateDate}
                        disabled={isBefore(parseISO(association.activates ?? ""), new Date())}
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !activateValidation.isValid,
                                helperText: activateValidation.reasonTag && (
                                    <LocalizeText tag={activateValidation.reasonTag as TLanguageTag} />
                                ),
                            },
                        }}
                        onChange={onChangeActivationDate}
                    />
                </ListItem>
                <ListItem>
                    <LocalizeText tag="deactivationDateInfo" />
                </ListItem>
                <ListItem>
                    <DatePicker
                        minDate={getMinDeactivationDate}
                        maxDate={getMaxDeactivationDate}
                        format={dateFormats.WEBDATE}
                        label={deactivateNow ? "" : <LocalizeText tag="deactivationDate" />}
                        value={deactivateNow ? null : deactivateDate}
                        disabled={deactivateNow}
                        autoFocus
                        slotProps={{
                            textField: {
                                fullWidth: true,
                                error: !deactivateValidation.isValid,
                                helperText: helperText,
                            },
                        }}
                        onChange={onChangeDeactivationDate}
                    />
                </ListItem>
                {hasPermission("is_community_user") && isBefore(parseISO(association.activates ?? ""), new Date()) ? (
                    <ListItem>
                        <Tooltip title={!canDeactivateNow ? <LocalizeText tag="deactivateNowDisabled" /> : ""}>
                            <FormControlLabel
                                label={<LocalizeText tag="deactivateNow" />}
                                control={
                                    <Checkbox
                                        disabled={!canDeactivateNow}
                                        checked={deactivateNow}
                                        onChange={(e) => setDeactivateNow(e.target.checked)}
                                    />
                                }
                            />
                        </Tooltip>
                    </ListItem>
                ) : null}
                <ListItem>
                    <VKButton template="cancel" tag="cancel" sx={{ marginRight: "auto" }} onClick={closeDrawers} />
                    <VKButton
                        template="primary"
                        tag="save"
                        sx={{ marginLeft: "auto" }}
                        disabled={
                            !email ||
                            !emailValid ||
                            !activateDate ||
                            !activateValidation.isValid ||
                            !deactivateValidation.isValid
                        }
                        onClick={onConfirm}
                        isLoading={isUpdatingAssociation}
                    />
                </ListItem>
                {!isBefore(parseISO(association.activates ?? ""), new Date()) ? (
                    <div
                        style={{
                            display: "flex",
                            marginTop: "130px",
                            marginBottom: "10px",
                        }}
                    >
                        <VKButton tag="removeUser" variant="outlined" onClick={() => setConfirmRemoveUser(true)} />
                    </div>
                ) : null}
            </List>
            <ConfirmDialog
                open={confirmDeactivate}
                title={<LocalizeText tag="deactivateMemberNow" />}
                description={
                    <LocalizeText
                        tag={"deactivateMemberNowSure"}
                        replace={{
                            email: association.member.email,
                        }}
                    />
                }
                onReject={() => setConfirmDeactivate(false)}
                onAccept={onRemoveAssociation}
                isLoading={isRemovingAssociation}
            />
            <ConfirmDialog
                open={confirmRemoveUser}
                title={<LocalizeText tag="removeUser" />}
                description={<LocalizeText tag={"removeUserSure"} />}
                onReject={() => setConfirmRemoveUser(false)}
                onAccept={onRemoveAssociation}
                isLoading={isRemovingAssociation}
            />
        </Box>
    );
};
