export interface IMember {
    uuid: string;
    created: string;
    updated: string;
    email: string;
    first_name: string;
    last_name: string;
    status: "INVITED" | "ACTIVE" | "DEACTIVATED";
}

export interface IMemberSubscriptionAssociation {
    uuid: string;
    created: string;
    updated: string;
    activates: string;
    deactivates: string;
    email: string;
    subscription: string;
    member: IMember;
    can_deactivate_now: boolean;
    should_send_mail: boolean;
    is_future_additional: boolean;
}

export interface IMembersQueryParams {
    org_nr?: string;
    membership_id?: string;
    search?: string;
}

export interface IAssociationsQueryParams {
    member_email?: string;
    member_uuid?: string;
    subscription_uuid?: string;
    subscription_uuids?: string;
    org_nr?: string;
    membership_id?: string;
    search?: string;
}

export interface IMembers {
    member?: IMember;
    association?: IMemberSubscriptionAssociation;
    isFetchingMember: boolean;
    isFetchingAssociation: boolean;
    isCreatingAssociation: boolean;
    isDeactivatingAssociation: boolean;
    isRemovingAssociation: boolean;
    isResendingInvitation: boolean;
    isUpdatingAssociation: boolean;
    fetchMember: (id: string) => Promise<IMember>;
    fetchAssociation: (id: string) => Promise<IMemberSubscriptionAssociation>;
    createAssociation: (data: Record<string, unknown>) => Promise<IMemberSubscriptionAssociation>;
    deactivateAssociation: (id: string, date: Date) => Promise<void>;
    removeAssociation: (id: string) => Promise<boolean>;
    resendInvitation: (uuid: string) => Promise<void>;
    updateAssociation: (
        associationId: string,
        data: Record<string, unknown>
    ) => Promise<IMemberSubscriptionAssociation>;
}

export const initialState: IMembers = {
    isFetchingMember: false,
    isFetchingAssociation: false,
    isCreatingAssociation: false,
    isDeactivatingAssociation: false,
    isRemovingAssociation: false,
    isResendingInvitation: false,
    isUpdatingAssociation: false,
    fetchMember: async () => ({} as IMember),
    fetchAssociation: async () => ({} as IMemberSubscriptionAssociation),
    createAssociation: async () => ({} as IMemberSubscriptionAssociation),
    deactivateAssociation: async (): Promise<void> => {
        return;
    },
    removeAssociation: async () => false,
    resendInvitation: async (): Promise<void> => {
        return;
    },
    updateAssociation: async () => ({} as IMemberSubscriptionAssociation),
};

export type IAction =
    | { type: "FETCH_MEMBER" }
    | { type: "FETCH_MEMBER_SUCCESS"; member: IMember }
    | { type: "FETCH_MEMBER_FAILURE" }
    | { type: "FETCH_ASSOCIATION" }
    | { type: "FETCH_ASSOCIATION_SUCCESS"; association: IMemberSubscriptionAssociation }
    | { type: "FETCH_ASSOCIATION_FAILURE" }
    | { type: "CREATE_ASSOCIATION" }
    | { type: "CREATE_ASSOCIATION_SUCCESS"; association: IMemberSubscriptionAssociation }
    | { type: "CREATE_ASSOCIATION_FAILURE" }
    | { type: "DEACTIVATE_ASSOCIATION" }
    | { type: "DEACTIVATE_ASSOCIATION_SUCCESS" }
    | { type: "DEACTIVATE_ASSOCIATION_FAILURE" }
    | { type: "REMOVE_ASSOCIATION" }
    | { type: "REMOVE_ASSOCIATION_SUCCESS" }
    | { type: "REMOVE_ASSOCIATION_FAILURE" }
    | { type: "RESEND_INVITATION" }
    | { type: "RESEND_INVITATION_SUCCESS" }
    | { type: "RESEND_INVITATION_FAILURE" }
    | { type: "UPDATE_ASSOCIATION" }
    | { type: "UPDATE_ASSOCIATION_SUCCESS"; association: IMemberSubscriptionAssociation }
    | { type: "UPDATE_ASSOCIATION_FAILURE" };

function reducer(state: IMembers, action: IAction): IMembers {
    switch (action.type) {
        case "FETCH_MEMBER":
            return { ...state, isFetchingMember: true };
        case "FETCH_MEMBER_SUCCESS":
            return { ...state, isFetchingMember: false, member: action.member };
        case "FETCH_MEMBER_FAILURE":
            return { ...state, isFetchingMember: false, member: undefined };

        case "FETCH_ASSOCIATION":
            return { ...state, isFetchingAssociation: true };
        case "FETCH_ASSOCIATION_SUCCESS":
            return { ...state, isFetchingAssociation: false, association: action.association };
        case "FETCH_ASSOCIATION_FAILURE":
            return { ...state, isFetchingAssociation: false };

        case "CREATE_ASSOCIATION":
            return { ...state, isCreatingAssociation: true };
        case "CREATE_ASSOCIATION_SUCCESS":
            return { ...state, isCreatingAssociation: false, association: action.association };
        case "CREATE_ASSOCIATION_FAILURE":
            return { ...state, isCreatingAssociation: false };

        case "DEACTIVATE_ASSOCIATION":
            return { ...state, isDeactivatingAssociation: true };
        case "DEACTIVATE_ASSOCIATION_SUCCESS":
            return { ...state, isDeactivatingAssociation: false, association: undefined };
        case "DEACTIVATE_ASSOCIATION_FAILURE":
            return { ...state, isDeactivatingAssociation: false };

        case "REMOVE_ASSOCIATION":
            return { ...state, isRemovingAssociation: true };
        case "REMOVE_ASSOCIATION_SUCCESS":
            return { ...state, isRemovingAssociation: false, association: undefined };
        case "REMOVE_ASSOCIATION_FAILURE":
            return { ...state, isRemovingAssociation: false };

        case "RESEND_INVITATION":
            return { ...state, isResendingInvitation: true };
        case "RESEND_INVITATION_SUCCESS":
            return { ...state, isResendingInvitation: false };
        case "RESEND_INVITATION_FAILURE":
            return { ...state, isResendingInvitation: false };

        case "UPDATE_ASSOCIATION":
            return { ...state, isUpdatingAssociation: true };
        case "UPDATE_ASSOCIATION_SUCCESS":
            return { ...state, isUpdatingAssociation: false, association: action.association };
        case "UPDATE_ASSOCIATION_FAILURE":
            return { ...state, isUpdatingAssociation: false };

        default:
            return { ...state };
    }
}

export default reducer;
