import React, { ReactNode } from "react";
import {
    TableFooter,
    TableSortLabel,
    TableCell,
    TableRow,
    TableBody,
    TableHead,
    Table,
    Box,
    Toolbar,
    LabelDisplayedRowsArgs,
    TablePagination,
    styled,
} from "@mui/material";
import If from "components/__helpers__/if";
import { TListOrder, TAlignment, THeadCell, TMouseEvent } from "utils/types";
import { LocalizeText } from "components/localizer";
import { IPaginationStore } from "utils/paginationStore";
import Row, { EmptyCell } from "./tableComponents/row";
import SkeletonTable, { SkeletonCell, TSkeletonVariant } from "./tableComponents/skeletonTable";
import colors from "styles/colors";
import { TLanguageTag } from "contexts/language";

const StyledTable = styled(Table)(() => ({
    borderCollapse: "separate",
    "& thead": {
        backgroundColor: colors.white,
        border: "none",
        boxShadow: "none",
        "& th": {
            fontWeight: "bold",
            borderBottom: `2px solid ${colors.border} !important`,
        },
        "& th:first-of-type": {
            borderBottom: `2px solid ${colors.white} !important`,
        },
        "& th:last-child": {
            borderBottom: `2px solid ${colors.white} !important`,
        },
    },
    "& tbody": {
        backgroundColor: colors.white,
        "& .MuiTableRow-hover": {
            cursor: "pointer",
        },
    },
    "& .MuiTableCell-footer": {
        color: colors.black,
        fontSize: 16,
    },
    "& .MuiTableCell-root": {
        "& span": {
            whiteSpace: "nowrap",
        },
        whiteSpace: "nowrap",
    },
    "& .MuiTableCell-sizeSmall": {
        padding: "8px 24px 8px 16px",
    },
    "& .MuiTableCell-sizeSmall:last-child": {
        padding: "inherit",
    },
    "& button :not(.MuiCollapse-root)": {
        padding: 0,
    },
}));

export interface IRow {
    data: React.ReactNode[];
    onClick?: () => void;
    collapsibleContent?: ReactNode;
    disabled?: boolean;
    prependIcons?: React.ReactNode[];
    subRows?: ISubRow[];
    tooltipText?: string | React.ReactNode;
}

export interface ISubRow {
    freeform: React.ReactNode;
    alignedData: React.ReactNode[];
    onClick?: () => void;
    hover?: boolean;
}

export interface ITableComponentProps {
    headCells: THeadCell[];
    alignment?: TAlignment;
    onSortChange?: (orderBy: string, sortOrder: TListOrder) => void;
    isLoading?: boolean;
    skeletonVariant?: TSkeletonVariant;
    rowsPerPageOptions?: number[];
    footer?: React.ReactNode;
    rows: IRow[];
    pagination?: IPaginationStore;
    collapsible?: boolean;
    tableSize?: "small" | "medium";
    hasPrependIcons?: boolean;
    paginationPageLabel?: (paginationInfo: LabelDisplayedRowsArgs) => React.ReactNode;
    customNoDataTag?: TLanguageTag;
}

interface IState {
    sortOrder: TListOrder;
    orderBy: string;
    page: number;
    rowsPerPage: number;
}

const TableComponent: React.FC<ITableComponentProps> = ({
    headCells,
    alignment,
    onSortChange,
    isLoading,
    skeletonVariant,
    rowsPerPageOptions,
    footer,
    rows,
    pagination,
    collapsible,
    tableSize = "medium",
    hasPrependIcons,
    paginationPageLabel,
    customNoDataTag,
}) => {
    const [state, setState] = React.useState<IState>({
        sortOrder: "desc",
        orderBy: "",
        page: 0,
        rowsPerPage: 10,
    });

    const onSortHandler = (property: string) => (event: TMouseEvent) => {
        let newSort: TListOrder = "desc";

        if (state.orderBy === property) {
            newSort = state.sortOrder === "desc" ? "asc" : "desc";
        }

        if (onSortChange) {
            onSortChange(property, newSort);
        } else if (pagination) {
            pagination.setParam("ordering", `${newSort === "desc" ? "-" : ""}${property}`);
            pagination.getInitial();
        }
        setState({ ...state, sortOrder: newSort, orderBy: property });
    };

    const onPageHandler = (event: unknown, newPage: number): void => {
        if (pagination) {
            pagination.changePage(newPage);
        } else {
            setState({ ...state, page: newPage });
        }
    };

    const onHandleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const rowsPerPage = Number(event.target.value);
        if (pagination) {
            pagination.setParam("page_size", rowsPerPage);
            pagination.getInitial();
        }
    };

    const mapRows = (data: IRow[]): React.ReactElement[] => {
        return data.map((row: IRow, index: number) => (
            <Row
                key={index}
                row={row}
                index={index}
                collapsible={collapsible}
                headCells={headCells}
                hasPrependIcons={hasPrependIcons}
            />
        ));
    };

    const initializeRowsPerPage = (): number => {
        if (pagination) {
            return Number(pagination.getParam("page_size"));
        }
        if (rowsPerPageOptions) {
            return rowsPerPageOptions[0];
        }
        if (tableSize === "small") {
            return 5;
        }
        return state.rowsPerPage;
    };

    const data = mapRows(rows);

    let page = state.page;
    let count = data?.length || 0;
    let noDataAvailable = count === 0;
    if (pagination) {
        page = pagination.page;
        count = pagination.totalCount;
        noDataAvailable = pagination.totalCount === 0;
    }
    const perPage = initializeRowsPerPage();

    return (
        <Box
            sx={{
                backgroundColor: colors.white,
                display: "table",
                width: "100%",
            }}
        >
            <StyledTable data-testid="tableComponent-table" size={tableSize}>
                <TableHead>
                    <TableRow>
                        <EmptyCell />
                        <If truthy={collapsible ?? hasPrependIcons}>
                            <TableCell />
                        </If>
                        {headCells.map((cell, index) => (
                            <TableCell
                                data-testid={`tableComponent-cell-withId_${cell.id}`}
                                key={cell.id}
                                style={{ verticalAlign: headCells[index].alignCellVertical ?? "baseline" }}
                                align={alignment ?? cell.alignCell ?? "left"}
                                sortDirection={state.sortOrder}
                            >
                                {cell.sortId ? (
                                    <TableSortLabel
                                        active={state.orderBy === cell.sortId}
                                        direction={state.sortOrder}
                                        onClick={onSortHandler(cell.sortId)}
                                    >
                                        {cell.label}
                                    </TableSortLabel>
                                ) : (
                                    <>{cell.label}</>
                                )}
                            </TableCell>
                        ))}
                        <EmptyCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isLoading ? (
                        <>
                            {!noDataAvailable ? (
                                <>{data}</>
                            ) : (
                                <TableRow>
                                    <EmptyCell />
                                    <TableCell colSpan={headCells.length} align="center">
                                        <LocalizeText tag={customNoDataTag ?? "noDataAvailable"} />
                                    </TableCell>
                                    <EmptyCell />
                                </TableRow>
                            )}
                        </>
                    ) : (
                        <SkeletonTable
                            variant={skeletonVariant ?? "regular"}
                            cellCount={collapsible || hasPrependIcons ? headCells.length + 1 : headCells.length}
                            perPage={perPage}
                        />
                    )}
                </TableBody>
                <TableFooter>{footer}</TableFooter>
            </StyledTable>
            {pagination ? (
                <>
                    {isLoading ? (
                        <Toolbar sx={{ minHeight: "3em", gap: "1em" }}>
                            <div style={{ flex: "1 1 100%" }} />
                            <SkeletonCell variant="rectangular" style={{ flexShrink: 0, width: "4em" }} />
                            <SkeletonCell variant="rectangular" style={{ flexShrink: 0, width: "4em" }} />
                        </Toolbar>
                    ) : (
                        <TablePagination
                            sx={{
                                "& .MuiIconButton-root": {
                                    padding: "0 12px",
                                    borderRadius: "0",
                                },
                                "& .MuiTablePagination-toolbar": {
                                    minHeight: tableSize === "small" ? "3em" : "inherit",
                                },
                            }}
                            onPageChange={onPageHandler}
                            onRowsPerPageChange={onHandleChangeRowsPerPage}
                            rowsPerPageOptions={
                                rowsPerPageOptions ??
                                Array.from(new Set([perPage, 20, 50, 100])).sort((n1, n2) => n1 - n2)
                            }
                            component="div"
                            count={count}
                            rowsPerPage={perPage}
                            page={page}
                            labelDisplayedRows={paginationPageLabel}
                        />
                    )}
                </>
            ) : (
                <Box sx={{ height: "1em" }} />
            )}
        </Box>
    );
};

export default TableComponent;
