import React from "react";
import { DomainContentType, IRole } from "reducers/roleManagement";
import { RoleManagementContext } from "contexts/roleManagement";
import { TPaginationActionData } from "utils/paginationStore";
import { ModalContext } from "contexts/modals";
import { CircularProgress, List, ListItem, ListItemText, MenuItem, TextField } from "@mui/material";
import { LocalizeText } from "components/localizer";
import CommunityContext from "contexts/community";
import VKButton from "components/vkButton";
import { ICommunityStaff, IEditCommunityStaff, INewCommunityStaff } from "reducers/community";
import { TCommunity } from "utils/ecommerseTypes";

const UpdateCommunityStaff: React.FC<{ user: ICommunityStaff }> = ({ user }) => {
    const { rolesPagination, fetchRolesOfDomain, isFetchingRoles } = React.useContext(RoleManagementContext);
    const { closeDrawers } = React.useContext(ModalContext);
    const { communitiesPagination, isFetchingCommunities, editCommunityStaff, isEditingCommunityStaff } =
        React.useContext(CommunityContext);

    const [editedStaffUser, setEditedStaffUser] = React.useState<IEditCommunityStaff>({
        communities: user.communities.flatMap((community) => community.uuid),
        role: user.role.id,
    });

    React.useEffect(() => {
        communitiesPagination.getInitial();
        rolesPagination.initialize(rolesPaginationCallback);
        rolesPagination.appendResults = true;
        rolesPagination.getInitial();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const rolesPaginationCallback = async (params: Record<string, unknown>): Promise<TPaginationActionData<IRole>> => {
        return fetchRolesOfDomain(DomainContentType.Community, params);
    };

    const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        const success = await editCommunityStaff(user.id, editedStaffUser);
        if (success) {
            closeDrawers();
        }
    };

    const onChange = (type: keyof INewCommunityStaff, value: string | number): void => {
        setEditedStaffUser({ ...editedStaffUser, [type]: value });
    };

    const validateData = (): boolean => {
        return editedStaffUser.role > 0 && !!editedStaffUser.communities.length;
    };

    const getAllRoles = (): IRole[] => {
        const combined = [...rolesPagination.results, user.role];
        const unique = new Map(combined.map((item) => [item["id"], item])).values();
        return Array.from(unique);
    };

    const getAllCommunities = (): TCommunity[] => {
        const combined = [...communitiesPagination.results, ...user.communities];
        const unique = new Map(combined.map((item) => [item["uuid"], item])).values();
        return Array.from(unique);
    };

    const hasEditPermission = rolesPagination.results.find((role) => role.id === user.role.id);
    const roles = getAllRoles();
    const communities = getAllCommunities();

    if (isFetchingRoles || isFetchingCommunities) {
        return <CircularProgress size={30} sx={{ position: "absolute" }} />;
    }
    return (
        <div data-testid="addContactPerson-root">
            <List>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="firstName" />} secondary={user.first_name} />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="lastName" />} secondary={user.last_name} />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="email" />} secondary={user.email} />
                </ListItem>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="phoneNumber" />} secondary={user.phone} />
                </ListItem>
                <form onSubmit={handleSubmit}>
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="role" />}
                            value={editedStaffUser.role}
                            required
                            sx={{ width: "100%" }}
                            disabled={roles.length <= 1 || !hasEditPermission}
                        >
                            {roles.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.display_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="arenas" />}
                            value={editedStaffUser.communities}
                            required
                            onChange={(event) => onChange("communities", event.target.value)}
                            sx={{ width: "100%" }}
                            SelectProps={{
                                multiple: true,
                            }}
                        >
                            {communities.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item.uuid}
                                    disabled={!communitiesPagination.results.find((c) => c.uuid === item.uuid)}
                                >
                                    {item.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <VKButton
                            isLoading={isEditingCommunityStaff}
                            disabled={!validateData()}
                            data-testid="addContactPerson-submitButton"
                            id="button"
                            tag="save"
                            type="submit"
                            template="save"
                        />
                    </ListItem>
                </form>
            </List>
        </div>
    );
};

export default UpdateCommunityStaff;
