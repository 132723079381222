import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { addDays, format, isAfter, isBefore, isValid, max, parseISO, sub } from "date-fns";
import VKButton from "components/vkButton";
import { dateFormats } from "utils/formats";
import { LocalizeText } from "components/localizer";
import { TFormEvent } from "utils/types";
import { ListItemText, List, ListItem, Box } from "@mui/material";
import ArrowIcon from "@mui/icons-material/ArrowForward";
import { fetchProductsAvailability } from "adapters/membershipAdapter";
import { MembershipsContext } from "contexts/memberships";
import { ISubscription } from "reducers/subscriptions";
import { usePermission } from "utils/hooks/usePermission";
import { TLanguageTag } from "contexts/language";
import { DatePicker } from "components/datePicker";

interface IProps {
    onSubmit: (date: Date) => void;
    subscription: ISubscription;
}

export const CancelSubscriptionDatePicker: FC<IProps> = ({ onSubmit, subscription }) => {
    const { membership } = useContext(MembershipsContext);
    const { hasPermission } = usePermission();
    const [validation, setValidation] = useState({
        isValid: true,
        reasonTag: "",
    });

    const { earliest, officialEarliest } = useMemo(() => {
        const officialEarliest = parseISO(subscription.earliest_cancellation_date ?? "");
        const earliest = hasPermission("can_override_earliest_subscription") ? new Date() : officialEarliest;
        return { earliest, officialEarliest };
    }, [hasPermission, subscription.earliest_cancellation_date]);

    const [endDate, setEndDate] = useState<Date>(
        max([officialEarliest, subscription.end_date ? parseISO(subscription.end_date) : new Date()])
    );

    const validateDataCallback = useCallback(
        async (date: Date, membershipId: number) => {
            const subscriptionStartDate = new Date(subscription.start_date);

            if (!isValid(date)) {
                return setValidation({ isValid: false, reasonTag: "errorDate" });
            }

            if (subscriptionStartDate > date) {
                date = subscriptionStartDate;
            }

            const available = await fetchProductsAvailability(membershipId, date);

            if (available) {
                if (!isAfter(date, sub(earliest, { days: 1 }))) {
                    return setValidation({ isValid: false, reasonTag: "errorMinDate" });
                }
                if (available?.data.total_subscriptions - 1 < available?.data.total_office_slots) {
                    return setValidation({ isValid: false, reasonTag: "notEnoughSubscriptionsError" });
                }
                return setValidation({ isValid: true, reasonTag: "" });
            }
            return setValidation({ isValid: false, reasonTag: "" });
        },
        [earliest, subscription.start_date]
    );

    useEffect(() => {
        if (membership?.id) {
            validateDataCallback(parseISO(subscription.earliest_cancellation_date ?? ""), membership.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [membership?.id]);

    if (!membership) {
        return null;
    }

    const handleSubmit = (event: TFormEvent): void => {
        event.preventDefault();
        if (endDate) {
            onSubmit(endDate);
        }
    };

    const onChange = (date: unknown): void => {
        validateDataCallback(date as Date, membership?.id);
        setEndDate(date as Date);
    };

    return (
        <Box>
            <form onSubmit={handleSubmit}>
                <List>
                    <ListItem>
                        <ListItemText primary={<LocalizeText tag="selectEndDateSubscription" />} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={<LocalizeText tag="removeFromSpaceflowInfo" />} />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary={
                                <>
                                    <LocalizeText tag="earliestSubscriptionEndDate" />:{" "}
                                    {format(officialEarliest, dateFormats.WEBDATE)}
                                </>
                            }
                        />
                    </ListItem>
                    <ListItem>
                        <DatePicker
                            minDate={earliest}
                            format={dateFormats.WEBDATE}
                            label={<LocalizeText tag="endDate" />}
                            value={endDate}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !validation.isValid,
                                    helperText: validation.reasonTag && (
                                        <LocalizeText tag={validation.reasonTag as TLanguageTag} />
                                    ),
                                },
                            }}
                            onChange={onChange}
                        />
                    </ListItem>
                    {isBefore(endDate, officialEarliest) ? (
                        <ListItem>
                            <ListItemText primary={<LocalizeText tag="warningEarlierThanOfficial" />} />
                        </ListItem>
                    ) : null}
                    <ListItem>
                        <VKButton
                            type="submit"
                            tag="proceed"
                            disabled={!validation.isValid}
                            rightAddon={<ArrowIcon />}
                            template="primary"
                        />
                    </ListItem>
                </List>
            </form>
        </Box>
    );
};
