import React from "react";
import { parseISO, format } from "date-fns";
import { IArena } from "reducers/arenas";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import { dateFormats } from "utils/formats";
import { getExpireDate } from "utils/ecommerseTypes";
import { ReactNode } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import Dropdown, { TDropdownItem } from "components/dropdown";
import { IPaginationStore } from "utils/paginationStore";

interface IProps {
    pagination: IPaginationStore<IArena>;
    onDrawerOpen: (type: TDrawer, selectedArena: IArena) => void;
    loading: boolean;
}

export const arenaHeadCells: THeadCell[] = [
    { id: "community.city", label: <LocalizeText tag="city" /> },
    { id: "community.title", label: <LocalizeText tag="arena" /> },
    { id: "stockrecord.title", label: <LocalizeText tag="arenaOffice" /> },
    { id: "total_office_spaces", label: <LocalizeText tag="officeSpaces" /> },
    { id: "start_date", label: <LocalizeText tag="moveInDate" /> },
    { id: "binding_period", label: <LocalizeText tag="rentalPeriod" /> },
    { id: "", label: "" },
];

export type TDrawer = "SHOW_INFORMATION" | "MANAGE_USERS" | "CANCEL_ARENA" | "";

const ArenaTable: React.FC<IProps> = ({ pagination, onDrawerOpen, loading }) => {
    const getRentalPeriod = (arena: IArena): ReactNode => {
        if (!arena.end_date) {
            return <LocalizeText tag="untilFurtherNotice" />;
        }
        return (
            <span>
                <LocalizeText tag="until" />
                {". "} {getExpireDate(arena.end_date)}
            </span>
        );
    };

    const getDropDownItems = (arena: IArena): TDropdownItem[] => {
        return [
            {
                onClick: () => onDrawerOpen("SHOW_INFORMATION", arena),
                label: <LocalizeText tag="showArenaOffice" />,
            },
            {
                onClick: () => onDrawerOpen("CANCEL_ARENA", arena),
                label: <LocalizeText tag="cancelArena" />,
                dontShow: !!arena.end_date,
            },
        ];
    };

    const mapRows = (arenas: IArena[]): IRow[] => {
        return (
            arenas &&
            arenas.map((arena) => ({
                data: [
                    arena.community_city,
                    arena.community_title,
                    arena.office_name,
                    arena.total_office_spaces,
                    format(parseISO(arena.start_date ?? ""), dateFormats.WEBDATE),
                    getRentalPeriod(arena),
                    <Dropdown key={`${arena.id}_dropdown`} items={getDropDownItems(arena)} inRow />,
                ],
            }))
        );
    };

    return (
        <TableComponent
            headCells={arenaHeadCells}
            isLoading={loading}
            skeletonVariant="options"
            pagination={pagination}
            rows={mapRows(pagination.results)}
            rowsPerPageOptions={[]}
        />
    );
};

export default ArenaTable;
