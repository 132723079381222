import React from "react";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import CommunityContext from "contexts/community";
import TableComponent, { IRow } from "components/tableComponent";
import { Box, Tab, Tabs } from "@mui/material";
import { ModalContext } from "contexts/modals";
import AddCommunityStaff from "components/drawers/communityStaff/addCommunityStaff";
import Dropdown from "components/dropdown";
import UpdateCommunityStaff from "components/drawers/communityStaff/updateCommunityStaff";
import SearchField from "components/tableComponents/paginationSearchField";

const communityStaffHeadCells: THeadCell[] = [
    { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
    { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
    { id: "communities", label: <LocalizeText tag="arenas" /> },
    { id: "", label: "", alignCell: "right" },
];

const communityStaffInvitationsHeadCells: THeadCell[] = [
    { id: "name", sortId: "first_name", label: <LocalizeText tag="name" /> },
    { id: "email", sortId: "email", label: <LocalizeText tag="email" /> },
    { id: "communities", label: <LocalizeText tag="arenas" /> },
];

type StaffManagementTab = "USERS" | "INVITATIONS";

const CommunityStaffManagementView: React.FC = () => {
    const {
        communityStaffPagination,
        isFetchingCommunityStaff,
        communityStaffInvitationPagination,
        isFetchingCommunityStaffInvitations,
    } = React.useContext(CommunityContext);
    const { openDrawer } = React.useContext(ModalContext);
    const [selectedTab, setTab] = React.useState<StaffManagementTab>("USERS");

    React.useEffect(() => {
        communityStaffPagination.getInitial();
        communityStaffInvitationPagination.getInitial();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mapCommunityStaffRows = (): IRow[] =>
        communityStaffPagination.results.map((person, index) => {
            return {
                data: [
                    `${person.first_name} ${person.last_name}`,
                    person.email,
                    person.communities ? person.communities.flatMap((community) => community.title).join(", ") : "-",
                    <Dropdown
                        inRow
                        key={person.uuid}
                        items={[
                            {
                                onClick: () =>
                                    openDrawer(UpdateCommunityStaff, { user: person }, "editCommunityStaffUser"),
                                label: <LocalizeText tag="edit" />,
                            },
                        ]}
                    />,
                ],
            };
        });

    const mapCommunityStaffInvitationsRows = (): IRow[] =>
        communityStaffInvitationPagination.results.map((invite, index) => {
            return {
                data: [
                    `${invite.first_name} ${invite.last_name}`,
                    invite.email,
                    invite.communities ? invite.communities.flatMap((community) => community.title).join(", ") : "-",
                ],
            };
        });

    return (
        <div data-testid="communityStaff-root" className="contentWrapper">
            <Banner
                title="communityStaff"
                subTitle="bannerCommunityStaff"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar
                searchField={
                    <SearchField
                        pagination={
                            selectedTab === "USERS" ? communityStaffPagination : communityStaffInvitationPagination
                        }
                        tag={selectedTab === "USERS" ? "searchCommunityStaff" : "searchInvites"}
                    />
                }
                menuItems={[
                    {
                        onClick: () => openDrawer(AddCommunityStaff, {}, "addCommunityStaff"),
                        label: <LocalizeText tag="addCommunityStaff" />,
                    },
                ]}
            />
            <Box>
                <Tabs value={selectedTab} onChange={(event, value) => setTab(value)}>
                    <Tab label={<LocalizeText tag="communityStaff" />} value="USERS" />
                    <Tab label={<LocalizeText tag="invitations" />} value="INVITATIONS" />
                </Tabs>
                {selectedTab === "USERS" && (
                    <TableComponent
                        headCells={communityStaffHeadCells}
                        isLoading={isFetchingCommunityStaff}
                        rows={mapCommunityStaffRows()}
                        pagination={communityStaffPagination}
                    />
                )}
                {selectedTab === "INVITATIONS" && (
                    <TableComponent
                        headCells={communityStaffInvitationsHeadCells}
                        isLoading={isFetchingCommunityStaffInvitations}
                        rows={mapCommunityStaffInvitationsRows()}
                        pagination={communityStaffInvitationPagination}
                    />
                )}
            </Box>
        </div>
    );
};

export default CommunityStaffManagementView;
