import React, { FC, useContext, useEffect } from "react";
import { MembershipsContext } from "contexts/memberships";
import { LocalizeText } from "components/localizer";
import { TDropdownItem } from "components/dropdown";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import { MembershipStaffTable } from "components/tableComponents/membershipStaffTable";
import SearchFilterField from "components/searchFilterField";
import MembershipFilterInput from "components/searchFilterComponents/membershipFilter";
import { usePaginationStore } from "utils/usePaginationStore";

export const MembershipsStaffView: FC = () => {
    const { isFetchingEmailsCSV, getEmailsCSV } = useContext(MembershipsContext);

    const pagination = usePaginationStore("memberships", undefined, {
        page_size: 20,
        is_active: false
    });

    useEffect(() => {
        pagination.getInitial();
        // eslint-disable-next-line
    }, []);

    const topMenuItems: TDropdownItem[] = [
        {
            onClick: () => getEmailsCSV(),
            label: <LocalizeText tag="downloadEmails" />,
            disabled: isFetchingEmailsCSV,
        },
    ];

    return (
        <div data-testid="memberships-root" className="contentWrapper">
            <Banner
                title="memberships"
                subTitle="bannerMemberships"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar
                searchField={
                    <SearchFilterField
                        pagination={pagination}
                        label={<LocalizeText tag="searchMemberships" />}
                        filterFields={<MembershipFilterInput membershipPagination={pagination} />}
                    />
                }
                menuItems={topMenuItems}
            />
            <MembershipStaffTable pagination={pagination} />
        </div>
    );
};
