import React from "react";
import { ModalContext } from "contexts/modals";
import { CircularProgress, List, ListItem, MenuItem, TextField } from "@mui/material";
import { LocalizeText } from "components/localizer";
import validator from "utils/validator";
import { ListTextField } from "../drawerComponents";
import { sanitizePhoneNumber } from "utils/helpers";
import { LanguageContext } from "contexts/language";
import CommunityContext from "contexts/community";
import VKButton from "components/vkButton";
import { INewCommunityStaff } from "reducers/community";

const AddCommunityStaff: React.FC = () => {
    const { closeDrawers } = React.useContext(ModalContext);
    const { localize } = React.useContext(LanguageContext);
    const { communitiesPagination, isFetchingCommunities, addCommunityStaff, isAddingCommunityStaff } =
        React.useContext(CommunityContext);

    const stateInitValue = {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        //role: -1,
        communities: [],
    };
    const [newContact, setNewContact] = React.useState<INewCommunityStaff>(stateInitValue);

    React.useEffect(() => {
        communitiesPagination.getInitial();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (communitiesPagination.totalCount === 1) {
            setNewContact({
                ...newContact,
                communities: communitiesPagination.results.flatMap((community) => community.uuid),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communitiesPagination.results]);

    const handleSubmit = async (event: React.SyntheticEvent): Promise<void> => {
        event.preventDefault();
        const parsedPhoneNumber = sanitizePhoneNumber(newContact.phone);
        const success = await addCommunityStaff({ ...newContact, phone: parsedPhoneNumber });
        if (success) {
            closeDrawers();
        }
    };

    const onChange = (type: keyof INewCommunityStaff, value: string | number): void => {
        setNewContact({ ...newContact, [type]: value });
    };

    const validateData = (): boolean => {
        return (
            !!(validator.email(newContact.email) && validator.phoneNumber(newContact.phone)) &&
            //&& newContact.role > 0
            !!newContact.communities.length
        );
    };

    if (isFetchingCommunities) {
        return <CircularProgress size={30} sx={{ position: "absolute" }} />;
    }
    return (
        <div data-testid="addContactPerson-root">
            <List>
                <ListItem>
                    <LocalizeText tag={"addNewCommunityStaffHelperText"} />
                </ListItem>
                <form onSubmit={handleSubmit}>
                    <ListTextField
                        isEditing
                        testid="addContactPerson-firstName"
                        label={<LocalizeText tag="firstName" />}
                        data={newContact.firstName}
                        onChange={(e) => onChange("firstName", e.target.value)}
                    />
                    <ListTextField
                        isEditing
                        testid="addContactPerson-lastName"
                        label={<LocalizeText tag="lastName" />}
                        data={newContact.lastName}
                        onChange={(e) => onChange("lastName", e.target.value)}
                    />
                    <ListTextField
                        testid="addContactPerson-email"
                        notValid={!validator.email(newContact.email, true)}
                        label={<LocalizeText tag="email" />}
                        errorText={<LocalizeText tag="errorEmail" />}
                        data={newContact.email}
                        onChange={(e) => onChange("email", e.target.value)}
                        isEditing
                    />
                    <ListTextField
                        isEditing
                        testid="addContactPerson-phone"
                        notValid={!validator.phoneNumber(newContact.phone, true)}
                        label={<LocalizeText tag="phoneNumber" />}
                        data={newContact.phone}
                        onChange={(e) => onChange("phone", e.target.value)}
                        errorText={<LocalizeText tag="errorMobile" />}
                        placeholder={localize("mobileNumberHelperText")}
                    />
                    <ListItem>
                        <TextField
                            select
                            label={<LocalizeText tag="arena" />}
                            value={newContact.communities}
                            required
                            onChange={(event) => onChange("communities", event.target.value)}
                            sx={{ width: "100%" }}
                            SelectProps={{
                                multiple: true,
                            }}
                        >
                            {communitiesPagination.results.map((item, index) => (
                                <MenuItem key={item.uuid} value={item.uuid}>
                                    {item.title}
                                </MenuItem>
                            ))}
                        </TextField>
                    </ListItem>
                    <ListItem>
                        <VKButton
                            isLoading={isAddingCommunityStaff}
                            disabled={!validateData()}
                            data-testid="addContactPerson-submitButton"
                            id="button"
                            tag="save"
                            type="submit"
                            template="save"
                        />
                    </ListItem>
                </form>
            </List>
        </div>
    );
};

export default AddCommunityStaff;
