import React, { ChangeEvent, FC, useCallback, useContext, useMemo, useState } from "react";
import { parseISO, format } from "date-fns";
import { LocalizeText } from "components/localizer";
import { THeadCell } from "utils/types";
import { dateFormats } from "utils/formats";
import { getSubscriptionPeriod } from "utils/ecommerseTypes";
import TableComponent, { IRow } from "components/tableComponent";
import { useFeatureToggle } from "utils/hooks/useFeatureToggle";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { getUserText } from "utils/helpers";
import StyledSwitchContainer from "./switchContainer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { IPaginationStore } from "utils/paginationStore";
import { ISubscription } from "reducers/subscriptions";
import { QueryClientContext } from "@tanstack/react-query";

interface IProps {
    pagination: IPaginationStore<ISubscription>;
    loading: boolean;
}

export const SubscriptionsTable: FC<IProps> = ({ pagination, loading }) => {
    const isActive = useFeatureToggle();
    const [filterActiveSubscription, setActiveFilter] = useState<boolean>(false);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const navigate = useNavigate();
    const queryClient = useContext(QueryClientContext);

    const subscriptionsHeadCells = useCallback((): THeadCell[] => {
        return [
            {
                id: "id",
                sortId: "id",
                label: <LocalizeText tag="id" />,
            },
            { id: "community_city", sortId: "product__community__city", label: <LocalizeText tag="city" /> },
            { id: "community_title", sortId: "product__community__title", label: <LocalizeText tag="arena" /> },
            ...(isActive("national-access")
                ? [{ id: "national_access", label: <LocalizeText tag="nationalAccess" /> }]
                : []),
            { id: "start_date", sortId: "start_date", label: <LocalizeText tag="activation" /> },
            { id: "binding_period", sortId: "end_date", label: <LocalizeText tag="subscriptionPeriod" /> },
            { id: "members", sortId: "active_associations", label: <LocalizeText tag="members" /> },
        ];
    }, [isActive]);

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((sub) => ({
            onClick: () => navigate(`/subscriptions/${sub.membership_id}/${sub.uuid}/details`),
            data: [
                sub.identifier,
                sub.community_city,
                sub.community_title,
                format(parseISO(sub.start_date ?? ""), dateFormats.WEBDATE) || "-",
                getSubscriptionPeriod(sub),
                getUserText(sub, largeScreen),
            ],
        }));
    }, [pagination.results, largeScreen, navigate]);

    const onIncludeInactiveChange = (event: ChangeEvent<HTMLInputElement>): void => {
        pagination.setParam("active", !event.target.checked ? true : null);
        queryClient?.invalidateQueries({ queryKey: ["subscriptions"] });
        setActiveFilter(event.target.checked);
    };

    return (
        <Box>
            <StyledSwitchContainer>
                <FormControlLabel
                    control={
                        <Switch checked={filterActiveSubscription} onChange={onIncludeInactiveChange} color="primary" />
                    }
                    label={<LocalizeText tag="includeInactiveSubscriptions" />}
                />
            </StyledSwitchContainer>
            <TableComponent
                headCells={subscriptionsHeadCells()}
                isLoading={loading}
                skeletonVariant="options"
                pagination={pagination}
                rows={rows}
            />
        </Box>
    );
};
