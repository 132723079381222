import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { format, isFuture, isToday, isValid, parseISO } from "date-fns";
import List from "@mui/material/List";
import { LocalizeText } from "components/localizer";
import { dateFormats } from "utils/formats";
import { formatBindingPeriod, formatTrialPeriod, getSubscriptionPeriod } from "utils/ecommerseTypes";
import { IMembership } from "reducers/memberships";
import { ListItem, ListItemText } from "@mui/material";
import PrettifyPrice from "components/prettifyPrice";
import { ISubscription } from "reducers/subscriptions";
import { MembershipsContext } from "contexts/memberships";
import { dateIsFutureOrToday, dateIsPastOrToday, getUserText } from "utils/helpers";
import { ModalContext } from "contexts/modals";
import { EditSubscriptionTrialPeriod } from "./editSubscriptionTrialPeriod";
import { EditSubscriptionBindingPeriod } from "./editSubscriptionBindingPeriod";
import { CancelSubscription } from "./cancelSubscription";
import { ListItemWithButton } from "./listItemWithButton";
import { usePermission } from "utils/hooks/usePermission";
import { ListItemDiscounts } from "../listItemDiscounts";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SubscriptionsContext from "contexts/subscriptions";
import { EditSubscriptionStartDate } from "./editSubscriptionStartDate";

interface IProps {
    subscription: ISubscription;
    setSubscription: (data: ISubscription) => void;
}

export const SubscriptionInfoColumn: FC<IProps> = ({ subscription, setSubscription }) => {
    const { fetchMembership } = useContext(MembershipsContext);
    const { updateSubscription, fetchSubscription } = useContext(SubscriptionsContext);
    const { openDrawer } = useContext(ModalContext);
    const { closeDrawers } = useContext(ModalContext);
    const { hasPermission } = usePermission();
    const [membership, setMembership] = useState<IMembership | null>(null);
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up("xl"));
    const trialPeriodEnd = parseISO(subscription.trial_period_until ?? "");

    useEffect(() => {
        (async (): Promise<void> => {
            if (!membership) {
                const mem = await fetchMembership(parseInt(subscription.membership_id));
                setMembership(mem);
                setSubscription(await fetchSubscription(subscription.uuid));
            }
        })();
    }, [subscription.membership_id]);

    const onSubmitUpdate = useCallback(
        async (newData: Partial<ISubscription>): Promise<void> => {
            setSubscription(await updateSubscription(subscription.uuid, newData));
            closeDrawers();
        },

        [closeDrawers, subscription.uuid, updateSubscription]
    );

    return (
        <List>
            <ListItem divider>
                <ListItemText primary={<LocalizeText tag="subscriptionNumber" />} secondary={subscription.identifier} />
            </ListItem>
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="membership" />}
                    secondary={
                        <span style={{ display: "flex", flexDirection: "column" }}>
                            <span>
                                <LocalizeText tag="name" />: {membership?.organization.company_name ?? "-"}
                            </span>
                            <span>
                                <LocalizeText tag="customerNumber" />: {membership?.agreement_nr ?? "-"}
                            </span>
                        </span>
                    }
                />
            </ListItem>
            <ListItem divider>
                <ListItemText primary={<LocalizeText tag="city" />} secondary={subscription.community_city} />
            </ListItem>
            <ListItem divider>
                <ListItemText primary={<LocalizeText tag="arena" />} secondary={subscription.community_title} />
            </ListItem>
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="members" />}
                    secondary={<span>{getUserText(subscription, largeScreen)}</span>}
                />
            </ListItem>
            <ListItemWithButton
                primary={<LocalizeText tag="activatesDate" />}
                secondary={format(parseISO(subscription.start_date ?? ""), dateFormats.WEBDATE)}
                button={{
                    onClick: () =>
                        openDrawer(
                            EditSubscriptionStartDate,
                            {
                                onSubmitUpdate: onSubmitUpdate,
                                dateStart: subscription.start_date,
                                dateEnd: subscription.end_date,
                            },
                            "editActivationStartDate"
                        ),
                    tag: "edit",
                    show:
                        hasPermission("can_change_subscription_start_date") &&
                        !subscription.final_payment_made &&
                        subscription.not_invoiced,
                }}
            />
            <ListItemWithButton
                primary={<LocalizeText tag="bindingPeriod" />}
                secondary={formatBindingPeriod(subscription.binding_period_until)}
                button={{
                    onClick: () =>
                        openDrawer(
                            EditSubscriptionBindingPeriod,
                            { subscription, onSubmitUpdate },
                            "editBindingPeriod"
                        ),
                    tag: "edit",
                    show:
                        hasPermission("change_subscription") &&
                        !dateIsPastOrToday(parseISO(subscription.end_date ?? "")) &&
                        !subscription.final_payment_made,
                }}
            />
            <ListItemWithButton
                primary={<LocalizeText tag="subscriptionPeriod" />}
                secondary={getSubscriptionPeriod(subscription)}
                button={{
                    onClick: () => openDrawer(CancelSubscription, { subscription }, "editFinish"),
                    tag: "edit",
                    show:
                        hasPermission("can_cancel_subscription") &&
                        (dateIsFutureOrToday(parseISO(subscription.end_date ?? "")) || !subscription.end_date) &&
                        !subscription.final_payment_made,
                }}
            />
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="subscriptionFee" />}
                    secondary={
                        <>
                            <PrettifyPrice amount={subscription.base_cost_excl_vat} decimals={0} />{" "}
                            <LocalizeText tag="excludingVat" />
                        </>
                    }
                />
            </ListItem>
            <ListItemWithButton
                primary={<LocalizeText tag="trialPeriod" />}
                secondary={formatTrialPeriod(subscription.trial_period_until ?? "", false)}
                button={{
                    onClick: () =>
                        openDrawer(EditSubscriptionTrialPeriod, { subscription, onSubmitUpdate }, "editTrialPeriod"),
                    tag: "editTrialPeriod",
                    show:
                        hasPermission("change_subscription") &&
                        isValid(trialPeriodEnd) &&
                        (isFuture(trialPeriodEnd) || isToday(trialPeriodEnd)) &&
                        !subscription.final_payment_made &&
                        subscription.not_invoiced,
                }}
            />
            <ListItemDiscounts
                type="subscriptions"
                type_id={subscription.id}
                max_amount_excl_vat={subscription.base_cost_excl_vat}
                subscription={subscription}
            />
        </List>
    );
};
