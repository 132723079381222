import React, { FC, useContext, useEffect } from "react";
import TopBar from "components/tableComponents/topBar";
import Banner from "components/banner";
import SearchField from "components/tableComponents/paginationSearchField";
import ExternalMembershipTable from "components/tableComponents/externalMembershipTable";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import CreateExternalMembership from "components/drawers/createExternalMembership";
import CommunityContext from "contexts/community";
import { usePaginationStore } from "utils/usePaginationStore";

const SergelConferenceView: FC = () => {
    const pagination = usePaginationStore("external-memberships", undefined, {
        agreement_type: "conference_member",
        is_archived: false,
        page_size: 20,
    });
    const { communitiesPagination } = useContext(CommunityContext);
    const { openDrawer } = useContext(ModalContext);

    useEffect(() => {
        communitiesPagination.setParam("community_type", "conference");
        communitiesPagination.getInitial();
        return () => {
            communitiesPagination.setParam("community_type", "");
        };
        // eslint-disable-next-line
    }, []);

    return (
        <div className="contentWrapper">
            <Banner
                title="sergel"
                subTitle="bannerSergel"
                image={`${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`}
            />
            <TopBar
                searchField={
                    <SearchField pagination={pagination} tag="searchSergelMemberships" validator="membership" />
                }
                singleItem={
                    communitiesPagination.results.length > 0 ? (
                        <VKButton
                            tag="createNew"
                            variant="outlined"
                            size="small"
                            onClick={() =>
                                openDrawer(
                                    CreateExternalMembership,
                                    { community_type: "conference" },
                                    "createNewSergelMembership"
                                )
                            }
                        />
                    ) : null
                }
            />
            <ExternalMembershipTable pagination={pagination} agreement_type="conference_member" hideCommunityColumn />
        </div>
    );
};

export default SergelConferenceView;
