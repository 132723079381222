import React, { FC, useContext, useMemo } from "react";
import TableComponent, { IRow } from "components/tableComponent";
import { format, parseISO } from "date-fns";
import { dateFormats } from "utils/formats";
import { LocalizeText, fontType } from "components/localizer";
import { Tooltip, Typography } from "@mui/material";
import PrettifyPrice from "components/prettifyPrice";
import colors from "styles/colors";
import { LanguageContext } from "contexts/language";
import { getMonthShort } from "utils/helpers";
import { THeadCell } from "utils/types";
import { EventRepeatOutlined, SystemSecurityUpdateGoodOutlined } from "@mui/icons-material";
import { usePaginationStore } from "utils/usePaginationStore";

const invoiceEventsHeadCells: THeadCell[] = [
    {
        id: "created",
        label: <LocalizeText tag="created" />,
    },
    { id: "community_title", label: <LocalizeText tag="arena" /> },
    { id: "type", label: <LocalizeText tag="type" /> },
    { id: "created_by", label: <LocalizeText tag="createdBy" /> },
    { id: "period", label: <LocalizeText tag="period" /> },
    { id: "total", label: <LocalizeText tag="totalIncludingVat" /> },
    { id: "invoice_count", label: <LocalizeText tag="invoices" /> },
];

interface IProps {
    onClick: (invoiceEventId: string) => void;
}

export const InvoiceEventsTable: FC<IProps> = ({ onClick }) => {
    const pagination = usePaginationStore("invoice-events", undefined, { page_size: 10 });
    const { currentLanguage } = useContext(LanguageContext);

    const rows = useMemo((): IRow[] => {
        return pagination.results.map((invoiceEvent) => {
            const {
                uuid,
                created,
                period_start_date,
                created_by,
                created_from_action,
                community,
                sum_invoiced_sek,
                orders_count,
                orders_not_invoiced_count,
            } = invoiceEvent;
            const isSubscriptions = created_from_action === "auto";
            const periodDate = parseISO(period_start_date ?? "");

            return {
                onClick: () => onClick(invoiceEvent.uuid),
                data: [
                    <Tooltip key={`created_${uuid}`} title={format(parseISO(created ?? ""), dateFormats.WEBDATETIME)}>
                        <span>{format(parseISO(created ?? ""), dateFormats.WEBDATE)}</span>
                    </Tooltip>,
                    community.title || "-",
                    <Tooltip
                        key={`type_${uuid}`}
                        title={<LocalizeText tag={isSubscriptions ? "subscriptions" : "appPurchases"} />}
                    >
                        <span>
                            {isSubscriptions ? (
                                <EventRepeatOutlined fontSize="small" />
                            ) : (
                                <SystemSecurityUpdateGoodOutlined fontSize="small" />
                            )}
                        </span>
                    </Tooltip>,
                    created_by ?? <LocalizeText tag="automaticRun" type={fontType.ITALIC} />,
                    `${getMonthShort(periodDate.getMonth(), currentLanguage)} ${periodDate.getFullYear().toString()}`,
                    <PrettifyPrice key={`total_${uuid}`} amount={sum_invoiced_sek.incl_vat} decimals={0} />,
                    <Typography key={`orders_count_${uuid}`} variant="inherit" style={{ display: "flex" }}>
                        {orders_count}{" "}
                        {orders_not_invoiced_count > 0 && (
                            <span style={{ color: colors.errorRed, marginLeft: 5 }}>({orders_not_invoiced_count})</span>
                        )}
                    </Typography>,
                ],
            };
        });
    }, [currentLanguage, pagination.results, onClick]);

    return (
        <TableComponent
            headCells={invoiceEventsHeadCells}
            isLoading={pagination.query.isFetching}
            rows={rows}
            skeletonVariant="options"
            pagination={pagination}
        />
    );
};
