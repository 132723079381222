import React, { FC, ReactNode, useContext, useState } from "react";
import { addDays, differenceInDays, format, isAfter, isSameDay, lastDayOfMonth, max, parseISO } from "date-fns";
import List from "@mui/material/List";
import { ButtonGroup, Grid, InputAdornment, ListItem, ListItemText, TextField } from "@mui/material";
import { LocalizeText } from "components/localizer";
import { dateFormats } from "utils/formats";
import VKButton from "components/vkButton";
import { ModalContext } from "contexts/modals";
import validator from "utils/validator";
import { IDiscount, TDiscountCategory, deleteDiscount, TDiscountType } from "adapters/discountsAdapter";
import PrettifyPrice from "components/prettifyPrice";
import { DatePicker } from "components/datePicker";
import { ISubscription } from "reducers/subscriptions";
import { LanguageContext, TLanguageTag } from "contexts/language";
import ConfirmDialog from "components/confirmDialog";
import { MonthSelector } from "components/monthSelector";
import { getMonth } from "utils/helpers";
import { usePermission } from "utils/hooks/usePermission";
import { Clear } from "@mui/icons-material";

const BUTTONS: { category: TDiscountCategory; tag: TLanguageTag }[] = [
    { category: "monthly", tag: "monthlyAmount" },
    { category: "one-time", tag: "oneTimeAmount" },
];

interface IProps {
    subscription: ISubscription;
    discount: IDiscount;
    max_amount_excl_vat: number;
    onUpdate: (updated: Partial<IDiscount>) => void;
    onDelete?: () => void;
    isCreatingNew?: boolean;
    type?: TDiscountType;
}

export const EditDiscountDrawer: FC<IProps> = ({
    subscription,
    discount,
    max_amount_excl_vat,
    onUpdate,
    onDelete,
    isCreatingNew,
    type = "subscriptions",
}) => {
    const { closeDrawers } = useContext(ModalContext);
    const { currentLanguage, localize } = useContext(LanguageContext);
    const { hasPermission } = usePermission();
    const [description, setDescription] = useState(discount.description);
    const [startDate, setStartDate] = useState(parseISO(discount.discount_from ?? ""));
    const [endDate, setEndDate] = useState<Date | undefined>(
        discount.discount_until ? parseISO(discount.discount_until ?? "") : undefined
    );
    const [category, setCategory] = useState(discount.category);
    const [amount, setAmount] = useState(discount.amount_excl_vat);
    const [confirmingDelete, setConfirmingDelete] = useState(false);

    const nextInvoiceDate = parseISO(subscription.next_invoice_date ?? "");

    const descriptionValid = validator.textFieldNotEmpty(description);
    const startDateValid = isAfter(startDate, addDays(nextInvoiceDate, -1));
    let endDateValid = true;
    if (endDate) {
        endDateValid =
            isAfter(endDate, addDays(nextInvoiceDate, -2)) &&
            (isSameDay(endDate, startDate) || isAfter(endDate, startDate));
    }

    const amountValid = parseFloat(amount) >= 0 && parseFloat(amount) <= max_amount_excl_vat;

    const canDeleteDiscount =
        hasPermission("delete_subscriptiondiscount") &&
        isAfter(startDate, addDays(nextInvoiceDate, -1)) &&
        !isCreatingNew &&
        !!onDelete;

    let categoryIndependentContent: ReactNode;
    if (isCreatingNew) {
        categoryIndependentContent = (
            <>
                <ListItem>
                    <TextField
                        label={<LocalizeText tag="description" />}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        fullWidth
                        error={!descriptionValid}
                    />
                </ListItem>
                <ListItem>
                    <Grid container direction="column" gap="20px">
                        <Grid item display="flex" flexDirection="column">
                            <ButtonGroup>
                                {BUTTONS.map((b) => (
                                    <VKButton
                                        key={b.category}
                                        tag={b.tag}
                                        variant={category === b.category ? "contained" : "outlined"}
                                        size="small"
                                        onClick={() => setCategory(b.category)}
                                        sx={{ height: 40 }}
                                    />
                                ))}
                            </ButtonGroup>
                            {category === "monthly" ? <LocalizeText tag="monthlyAmountExplanation" /> : null}
                        </Grid>
                        <TextField
                            label={<LocalizeText tag="priceAmount" />}
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            fullWidth
                            type="number"
                            inputProps={{ min: 0, max: max_amount_excl_vat }}
                            InputProps={{ endAdornment: <InputAdornment position="end">kr</InputAdornment> }}
                            error={!amountValid}
                            helperText={
                                <>
                                    <LocalizeText tag="priceAmount" /> <LocalizeText tag="excludingVat" />. Max:{" "}
                                    <PrettifyPrice amount={max_amount_excl_vat} />. (
                                    {!isNaN(parseFloat(amount))
                                        ? Math.round((parseFloat(amount) / max_amount_excl_vat) * 100)
                                        : "-"}
                                    %)
                                </>
                            }
                        />
                    </Grid>
                </ListItem>
            </>
        );
    } else {
        categoryIndependentContent = (
            <>
                <ListItem divider>
                    <ListItemText primary={<LocalizeText tag="description" />} secondary={discount.description} />
                </ListItem>
                <ListItem divider>
                    <ListItemText
                        primary={<LocalizeText tag="discount" />}
                        secondary={<PrettifyPrice amount={amount} />}
                    />
                </ListItem>
            </>
        );
    }

    let categoryContent: ReactNode;
    if (category === "monthly") {
        categoryContent = (
            <>
                {isCreatingNew || isAfter(parseISO(discount.discount_from ?? ""), addDays(nextInvoiceDate, -1)) ? (
                    <ListItem>
                        <DatePicker
                            minDate={nextInvoiceDate}
                            format={dateFormats.WEBDATE}
                            label={
                                <>
                                    <LocalizeText tag="startDate" /> (<LocalizeText tag="startOfDay" />)
                                </>
                            }
                            value={startDate}
                            slotProps={{ textField: { fullWidth: true, error: !startDateValid } }}
                            onChange={(newDate: Date) => {
                                setStartDate(newDate as Date);
                                if (endDate) {
                                    setEndDate(max([newDate as Date, endDate]));
                                } else {
                                    setEndDate(newDate as Date);
                                }
                            }}
                        />
                    </ListItem>
                ) : (
                    <ListItem divider>
                        <ListItemText
                            primary={<LocalizeText tag="startDate" />}
                            secondary={format(startDate, dateFormats.WEBDATE)}
                        />
                    </ListItem>
                )}
                {!endDate ? (
                    <ListItem>
                        <VKButton onClick={() => setEndDate(max([new Date(), startDate]))} fullWidth variant="outlined">
                            {`${localize("add")} ${localize("endDate").toLocaleLowerCase()}`}
                        </VKButton>
                    </ListItem>
                ) : (
                    <ListItem>
                        <DatePicker
                            minDate={isCreatingNew ? startDate : addDays(nextInvoiceDate, -1)}
                            format={dateFormats.WEBDATE}
                            label={
                                <>
                                    <LocalizeText tag="endDate" /> (<LocalizeText tag="endOfDay" />){" "}
                                </>
                            }
                            value={endDate}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    error: !endDateValid,
                                    helperText: endDate ? (
                                        <>
                                            {differenceInDays(endDate, startDate) + 1 || "0"}
                                            <LocalizeText tag="days" styling={{ marginLeft: "5px" }} />
                                        </>
                                    ) : null,
                                },
                            }}
                            onChange={(newDate: Date) => setEndDate(newDate as Date)}
                        />
                        <VKButton
                            variant="outlined"
                            sx={{
                                marginBottom: "auto",
                                marginLeft: "5px",
                                marginTop: "-1px",
                                height: "60px",
                                width: "60px",
                            }}
                            onClick={() => setEndDate(undefined)}
                        >
                            <Clear />
                        </VKButton>
                    </ListItem>
                )}
            </>
        );
    } else if (isCreatingNew) {
        categoryContent = (
            <ListItem>
                <MonthSelector
                    currentDate={startDate}
                    minDate={nextInvoiceDate}
                    onChange={(newDate) => {
                        setStartDate(newDate);
                        setEndDate(lastDayOfMonth(newDate));
                    }}
                />
            </ListItem>
        );
    } else {
        categoryContent = (
            <ListItem divider>
                <ListItemText
                    primary={<LocalizeText tag="period" />}
                    secondary={`${getMonth(startDate, currentLanguage)} ${startDate.getFullYear()}`}
                />
            </ListItem>
        );
    }

    return (
        <>
            <List>
                {categoryIndependentContent}

                {categoryContent}
            </List>

            {isCreatingNew || category === "monthly" ? (
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <VKButton template="cancel" tag="cancel" onClick={closeDrawers} />
                    </Grid>
                    <Grid item>
                        <VKButton
                            template="save"
                            tag={isCreatingNew ? "confirm" : "save"}
                            disabled={
                                !descriptionValid || !endDateValid || !amountValid || (isCreatingNew && !startDateValid)
                            }
                            onClick={() => {
                                const data: Partial<IDiscount> = {
                                    discount_until: endDate ? format(endDate, dateFormats.WEBDATE) : null,
                                };
                                if (isCreatingNew) {
                                    data.description = description;
                                    data.category = category;
                                    data.amount_excl_vat = amount;
                                    data.discount_from = format(startDate, dateFormats.WEBDATE);
                                }
                                onUpdate(data);
                            }}
                        />
                    </Grid>
                </Grid>
            ) : null}

            {canDeleteDiscount ? (
                <div
                    style={{
                        display: "flex",
                        marginTop: "auto",
                        marginBottom: "10px",
                    }}
                >
                    <VKButton tag="deleteDiscount" variant="outlined" onClick={() => setConfirmingDelete(true)} />
                </div>
            ) : null}

            <ConfirmDialog
                open={confirmingDelete}
                title={<LocalizeText tag="deleteDiscount" />}
                description={<LocalizeText tag="deleteDiscountDescription" />}
                onReject={() => setConfirmingDelete(false)}
                onAccept={() => {
                    deleteDiscount(type, subscription.id, discount.id);
                    setConfirmingDelete(false);
                    onDelete?.();
                    closeDrawers();
                }}
            />
        </>
    );
};
