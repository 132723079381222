import React, { FC, useMemo } from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { LocalizeText } from "components/localizer";
import PrettifyPrice from "components/prettifyPrice";
import { TLanguageTag } from "contexts/language";
import { TInvoiceType } from "reducers/invoices";
import { THeadCell } from "utils/types";
import TableComponent from "components/tableComponent";
import TagValueList, { ITagValueListEntries } from "components/tagValueList";
import VKButton from "components/vkButton";

export interface IInvoiceLineItem {
    name: string;
    description?: string;
    amount: number;
    onClick?: () => void;
}
export interface IInvoiceContentButton {
    tag: TLanguageTag;
    onClick: () => void;
    isLoading?: boolean;
}
interface IProps {
    titleTag?: TLanguageTag;
    items: IInvoiceLineItem[];
    currency?: TLanguageTag;
    type?: TInvoiceType;
    amount?: number | string;
    remaining?: number | string;
    infoColumns?: ITagValueListEntries[][];
    isLoadingItems?: boolean;
    buttons?: IInvoiceContentButton[];
}
export const InvoiceContent: FC<IProps> = ({
    titleTag,
    items,
    currency = "SEK",
    type = "NORMAL",
    amount,
    remaining,
    infoColumns,
    isLoadingItems,
    buttons,
}) => {
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up("lg"));

    const rows = useMemo(() => {
        return items.map(({ name, description, amount, onClick }, index) => ({
            onClick,
            data: [
                name,
                description ?? "",
                <PrettifyPrice key={`amount_${name}_${index}`} amount={amount} currency={currency} />,
            ],
        }));
    }, [items, currency]);

    return (
        <Grid container direction="column" gap="30px">
            {titleTag ? (
                <Grid item>
                    <Typography variant="h1" fontSize="2rem" fontFamily="Vasakronan">
                        <LocalizeText tag={titleTag} />
                    </Typography>
                </Grid>
            ) : null}
            {infoColumns ? (
                <Grid container gap="30px 60px" flexWrap={largeScreen ? "nowrap" : "wrap"}>
                    {infoColumns?.map((column, index) => (
                        <Grid key={`column_${index}`} item flexGrow={1}>
                            <TagValueList typographyProps={{ variant: "body2" }} entries={column} />
                        </Grid> 
                    ))}
                </Grid>
            ) : null}
            <Grid
                item
                container
                direction="row-reverse"
                justifyContent="space-between"
                alignItems="flex-end"
                margin="10px 0"
                gap="30px"
            >
                {buttons ? (
                    <Grid item container direction="column" width="250px" gap="10px">
                        {buttons?.map((b) => (
                            <Grid key={b.tag} item>
                                <VKButton template="primary" size="small" fullWidth {...b} />
                            </Grid>
                        ))}
                    </Grid>
                ) : null}
                {amount && remaining ? (
                    <Grid item marginRight="auto">
                        <BigNumbers amount={amount} remaining={remaining} currency={currency} type={type} />
                    </Grid>
                ) : null}
            </Grid>
            <TableComponent
                headCells={invoiceHeadCells}
                skeletonVariant="options"
                isLoading={isLoadingItems}
                rows={rows}
            />
        </Grid>
    );
};

const invoiceHeadCells: THeadCell[] = [
    { id: "product_identifier", label: <LocalizeText tag="product" /> },
    {
        id: "description",
        label: <LocalizeText tag="description" />,
        allowLineBreaks: true,
    },
    {
        id: "subtotal_price",
        alignCell: "right",
        label: (
            <>
                <LocalizeText tag="priceAmount" /> <LocalizeText tag="excludingVat" />
            </>
        ),
    },
];

interface IBigNumbersProps {
    amount: number | string;
    remaining: number | string;
    currency: TLanguageTag;
    type: TInvoiceType;
}
const BigNumbers: FC<IBigNumbersProps> = ({ amount, remaining, currency, type }) => {
    amount = typeof amount === "string" ? parseFloat(amount) : amount;
    remaining = typeof remaining === "string" ? parseFloat(remaining) : remaining;

    let content = (
        <>
            <BigInfo tag="totalIncludingVat" amount={amount} currency={currency} />
            <BigInfo tag="paidAmount" amount={amount - remaining} currency={currency} />
            <BigInfo tag="amountDueIncludingVat" amount={remaining} currency={currency} />
        </>
    );
    if (type === "CREDIT") {
        content = (
            <>
                <BigInfo tag="totalIncludingVat" amount={amount} currency={currency} />
                <BigInfo tag="usedAmount" amount={-(amount - remaining)} currency={currency} />
                <BigInfo tag="toReceiveIncludingVat" amount={-remaining} currency={currency} />
            </>
        );
    }

    return (
        <Grid container gap="20px 80px">
            {content}
        </Grid>
    );
};

interface BigInfoProps {
    tag: TLanguageTag;
    amount: string | number;
    currency: TLanguageTag;
}
const BigInfo: React.FC<BigInfoProps> = ({ tag, amount, currency }) => {
    return (
        <Grid item>
            <Typography variant="body1">
                <LocalizeText tag={tag} />
            </Typography>
            <Typography variant="h5">
                <PrettifyPrice amount={amount} currency={currency} />
            </Typography>
        </Grid>
    );
};
