import React from "react";
import Dashboard, { DashboardRootWrapper } from "views/Dashboard";
import NewsSidebar from "components/newsComponents/NewsSidebar";
import { permission } from "utils/permissions";

const ArenaCommunityStaffDashboardView: React.FC = () => {
    return (
        <DashboardRootWrapper container wrap="nowrap">
            <Dashboard
                banners={[
                    {
                        title: "memberships",
                        breadcrumbs: "bannerMemberships",
                        image: `${process.env.PUBLIC_URL}/assets/images/1024x683/memberships-1024x683.jpg`,
                        route: "memberships",
                        requiredPermissions: permission.MEMBERSHIPS
                    },
                    {
                        title: "contactPersons",
                        breadcrumbs: "bannerRepresentatives",
                        image: `${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`,
                        route: "representatives",
                        requiredPermissions: permission.REPRESENTATIVES
                    },
                    {
                        title: "bannerCostCalculationsTitle",
                        breadcrumbs: "bannerCostCalculations",
                        image: `${process.env.PUBLIC_URL}/assets/images/1024x683/subscriptions-1024x683.jpg`,
                        route: "cost-calculations",
                        requiredPermissions: permission.ARENA_STAFF_COSTS
                    }
                ]}
            />
            <NewsSidebar />
        </DashboardRootWrapper>
    );
};

export default ArenaCommunityStaffDashboardView;
