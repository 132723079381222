import React, { FC, useCallback, useContext } from "react";
import { Grid, Typography } from "@mui/material";
import { LocalizeText } from "components/localizer";
import { ICustomerLedger, ILease, ILeaseContract } from "reducers/leases";
import TagValueList, { ITagValueListEntries } from "components/tagValueList";
import { LanguageContext } from "contexts/language";
import { Download, Email } from "@mui/icons-material";
import { downloadFile, getInvoiceDestination } from "utils/helpers";
import { ModalContext } from "contexts/modals";
import HasPermission from "components/__helpers__/hasPermission";
import { usePaginationStore } from "utils/usePaginationStore";
import VKButton from "components/vkButton";
import SendLeaseQuestion from "components/dialogs/sendLeaseQuestionDialog";
import colors from "styles/colors";
import { format, sub } from "date-fns";
import { dateFormats } from "utils/formats";
import { NotificationContext } from "contexts/notification";

interface IProps {
    lease: ILease;
}
export const LeaseCollapse: FC<IProps> = ({ lease }) => {
    const { formatFromToDate } = useContext(LanguageContext);
    const { pushDialog } = useContext(ModalContext);
    const notifications = useContext(NotificationContext);

    const pagination = usePaginationStore("customer-ledgers", undefined, {
        ordering: "-due_date",
        lease: lease.lease_uuid,
        page_size: 100,
        due_date_after: format(sub(new Date(), { years: 1 }), dateFormats.APIDATE),
        has_pdf_url: true,
    });

    const mapLeaseListDetails = useCallback((): ITagValueListEntries[] => {
        const defaultLeaseContract = getDefaultLeaseContract(lease);
        return [
            {
                tag: "contactDuringPeriod",
                value: formatFromToDate(lease.valid_from_date, lease.valid_to_date),
                align: "left"
            },
            {
                tag: "debitAdress",
                value: defaultLeaseContract.full_billing_address,
                align: "left"
            },
            {
                tag: "invoiceIsSentTo",
                value: getInvoiceDestination(defaultLeaseContract),
                align: "left"
            },
            {
                tag: "vat",
                value: <LocalizeText tag={defaultLeaseContract.is_vat ? "yes" : "no"} />,
                align: "left"
            },
        ];
    }, [formatFromToDate, lease]);

    const onDownload = useCallback(
        (ledger: ICustomerLedger) => {
            downloadFile(`faktura_${ledger.invoice_number}`, ledger.invoice_pdf_url);
            notifications.enqueNotification("success_fileDownload");
        },
        [notifications]
    );

    return (
        <Grid container>
            <Grid container xs marginBottom="20px">
                <Grid
                    item
                    xs
                    sm={6}
                    sx={{
                        "& p": {
                            whiteSpace: "nowrap !important",
                        },
                    }}
                >
                    <TagValueList typographyProps={{ variant: "body2" }} entries={mapLeaseListDetails()} xsSize={4}/>
                </Grid>
                <Grid item xs={6} container justifyContent="flex-end">
                    <HasPermission requiredPerm="send_mail_question">
                        <VKButton
                            startIcon={<Email />}
                            tag="contactVasakronan"
                            size="small"
                            onClick={() => pushDialog(SendLeaseQuestion, { lease_uuid: lease.lease_uuid })}
                        />
                    </HasPermission>
                </Grid>
            </Grid>
            <Grid container gap="2px" direction="column">
                <LocalizeText tag="fromOneYearAndForward" />
                {pagination.results.map((ledger) => (
                    <Grid
                        key={ledger.customer_ledger_uuid}
                        item
                        bgcolor={colors.white}
                        display="flex"
                        alignItems="center"
                        padding="2px 5px"
                        gap="5px"
                        sx={{
                            cursor: "pointer",
                            "&:hover": {
                                textDecoration: "underline",
                            },
                            "&:active": {
                                opacity: 0.8,
                            },
                        }}
                        onClick={() => onDownload(ledger)}
                    >
                        <Download fontSize="small" sx={{ color: colors.greyText }} />
                        <Typography fontSize="1rem" color={colors.vkBlue}>
                            <LocalizeText tag="invoice" />_{ledger.invoice_number} (<LocalizeText tag="dueDate" />{" "}
                            {ledger.due_date})
                        </Typography>
                    </Grid>
                ))}
                {pagination.results.length === 0 ? (
                    <LocalizeText tag="noDataAvailable" styling={{ fontStyle: "italic" }} />
                ) : null}
            </Grid>
        </Grid>
    );
};

function getDefaultLeaseContract(lease: ILease): ILeaseContract {
    if (lease.active_lease_contract) {
        return lease.active_lease_contract;
    }

    return {
        full_billing_address: "-",
        debit_period: "-",
    } as ILeaseContract;
}
